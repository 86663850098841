import {cva} from 'class-variance-authority';

import Typography, {
  type TEXT_SIZES,
} from '@/components/base/elements/Typography/Typography';
import type {Mode} from '@/components/base/types';

import {Checkmark, Close} from './icons';

export interface ChecklistProps {
  itemsHtml: string[];
  itemsHeading?: string;
  mode?: Mode;
  iconType?: 'checkmark' | 'close';
  isCompact?: boolean;
  size?: (typeof TEXT_SIZES)[number];
}

const listStyles = cva('flex flex-col', {
  variants: {
    isCompact: {
      true: 'gap-y-md',
      false: 'gap-y-lg',
    },
  },
});

const listItemStyles = cva('flex text-body-lg', {
  variants: {
    isCompact: {
      true: 'gap-x-sm md:[&>svg]:mt-1',
      false: 'gap-x-md',
    },
    isBase: {
      true: 'gap-x-sm md:[&>svg]:mt-[3px]',
    },
  },
});

export default function Checklist({
  itemsHeading,
  itemsHtml,
  mode = 'light',
  iconType = 'checkmark',
  isCompact = false,
  size = 'body-lg',
}: ChecklistProps) {
  const Icon = iconType === 'checkmark' ? Checkmark : Close;
  const isBase = size === 'body-base';

  return (
    <>
      {itemsHeading && (
        <Typography size="t5" as="p">
          {itemsHeading}
        </Typography>
      )}
      <ul className={listStyles({isCompact})}>
        {itemsHtml.map(
          (item, index) =>
            item && (
              <li
                key={`item-${index}`}
                className={listItemStyles({isCompact, isBase})}
              >
                <Icon mode={mode} isCompact={isCompact} isBase={isBase} />
                <Typography
                  as="span"
                  size={size}
                  isRichtext
                  className="self-center"
                  mode={mode}
                >
                  {item}
                </Typography>
              </li>
            ),
        )}
      </ul>
    </>
  );
}
